.Landing,
.Error,
.Loading {
  height: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app.start .dcl.logo {
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
}

.Landing h3,
.Error h3 {
  font-size: 20px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.Landing input {
  width: 70%;
  max-width: 300px;
}

.Landing .help {
  color: var(--gray);
  font-size: 12px;
  margin: 12px 0;
}
