#chart {
  width: 100%;
  min-height: 400px;
  margin-bottom: 30px;
}

.tooltip > p {
  text-align: center;
  font-weight: 600;
  color: rgba(22, 20, 26, 0.5);
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.tooltip .price {
  display: none;
  font-weight: 400;
  font-size: 13px;
  color: rgba(22, 20, 26, 0.5);
}

.tooltip .price.MANA {
  display: block;
}

.tooltip table tr > td div {
  text-align: right;
}

.tooltip table tr > td {
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tooltip table tr:last-child > td {
  padding-top: 5px;
  padding-bottom: 0;
  border-bottom: none;
}

.tooltip table tr:only-child > td {
  padding-top: 0px;
}

.tooltip table tr > td:first-child {
  padding-right: 15px;
}
