.header {
  border-bottom: 1px solid var(--light-gray);
}

.header .dcl.logo {
  vertical-align: middle;
  margin: 10px 0;
}

.header-title {
  font-weight: 600;
  font-size: 19px;
  display: flex;
}

.header .dcl.user-menu .toggle,
.header .dcl.user-menu .toggle .avatar-face {
  width: 36px;
  height: 36px;
}

@media screen and (min-width: 768px) {
  .header .dcl.logo {
    height: 36px;
    width: 36px;
  }
}
