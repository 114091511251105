#progress {
  width: 100%;
  margin-bottom: 40px;
}

#progress .ui.grid .column {
  width: fit-content;
}

#progress .header {
  margin: 0;
}

#progress .amount {
  display: flex;
  align-items: center;
}

#progress .percentage {
  margin-left: 5px;
  color: white;
  padding: 0 5px;
  border-radius: 4px;
}

#progress .percentage.vested {
  background-color: var(--green);
}

#progress .percentage.released {
  background-color: var(--orange);
}
