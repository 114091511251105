.footer {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 15px;
  --footer-color: #736e7d;
  color: var(--footer-color);
}

.footer-top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-top: 1px solid rgba(115, 110, 125, 0.24);
  padding-top: 15px;
}

.footer-foundation {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.footer-links {
  display: inline-block;
}

.footer-links a {
  font-size: 12px;
  color: var(--footer-color);
  margin-left: 15px;
}

.footer-icons {
  display: inline-block;
}

.footer-icons a {
  color: var(--footer-color) !important;
}

.footer-icons svg {
  margin-left: 15px;
}

.footer-bottom {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 15px;
}

.footer-copyright {
  font-size: 15px;
  color: var(--footer-color);
}
